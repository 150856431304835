@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

:root{
  --header-height: 3rem;
  --font-medium: 500;
  --body-font: 'Montserrat', sans-serif;
  --big-font-size: 6.25rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --mb-1: .5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --z-fixed: 100;
  --first-color: #5361FF;
  --white-color: #FAFAFF;
  --dark-color: #2A3B47;
  --text-color: #697477;
}

@media screen and (min-width: 768px){
  :root{
      --big-font-size: 10.5rem;
      --h2-font-size: 2rem;
      --normal-font-size: 1rem;
      --small-font-size: .875rem;
  }
}

.bd-grid{
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}




